import { ImpressionItem } from './types';

export class ImpressionQueue<T extends ImpressionItem = ImpressionItem> {
    private _impressions: T[] = [];
    private _timer: ReturnType<typeof setTimeout> | null = null;
    static TIMER_DELAY_DEFAULT = 1000;

    constructor(
        private executor: (items: T[]) => Promise<void>,
        private opts: { delay: number } = {
            delay: ImpressionQueue.TIMER_DELAY_DEFAULT,
        }
    ) {}

    get impressions() {
        return this._impressions;
    }

    find(predicate: (item: T, index: number) => boolean) {
        return this.impressions.find(predicate);
    }

    queue(item: T): void {
        this.impressions.push(item);
    }

    clear(): void {
        this._impressions = [];
    }

    resetTimer(): void {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    startTimer(): void {
        this.resetTimer();

        this._timer = setTimeout(() => {
            this.flush();
        }, this.opts.delay);
    }

    flush(): void {
        if (this._impressions.length === 0) {
            return;
        }
        try {
            const eventsCloned = this._impressions.slice();
            this.clear();
            void this.executor(eventsCloned);
        } catch (_) {
            // catch errors to avoid breaking the flow
        }
    }
}
