import { ImpressionQueue } from '../impressionQueue';
import { ImpressionItem, Tracker } from '../types';
import { isImpressionEvent } from '../utils/isImpressionEvent';

const ARCHIPRO_IMPRESSION_LOGGING_URL = '/member/logImpression';
export const INVALID_IMPRESSION_DATA_ERROR =
    'Log impression must be called with data';

interface User {
    ID: number;
}

export const ARCHIPRO_IMPRESSION_TRACKER = 'archiproImpressionTracker';

export function createArchiproImpressionTracker(user: User): Tracker {
    const queue = new ImpressionQueue(promiseExecutor(user));

    return {
        name: ARCHIPRO_IMPRESSION_TRACKER,
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            if (!isImpressionEvent(event)) {
                return;
            } else if (!opts.data) {
                console.error(INVALID_IMPRESSION_DATA_ERROR);
                return;
            } else if (!isImpressionItem(opts.data)) {
                console.error('LogOpts data is not of type ImpressionItem');
                return;
            }

            if (!hasItemInQueue(opts.data, queue)) {
                queue.queue(opts.data);
                queue.startTimer();
            }
        },
        release() {
            queue.flush();
        },
    };
}

function hasItemInQueue(
    data: ImpressionItem,
    impressionQueue: ImpressionQueue
) {
    return impressionQueue.find(
        item => item.ID === data.ID && item.type === data.type
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isImpressionItem(data: any): data is ImpressionItem {
    if (!data) {
        return false;
    }
    return 'ID' in data && 'type' in data;
}

function promiseExecutor(
    user: User
): (items: ImpressionItem[]) => Promise<void> {
    return async (items: ImpressionItem[]) => {
        const itemsCloned = items.map(item => ({
            ...item,
            MemberID: user.ID,
        }));

        try {
            await fetch(`${ARCHIPRO_IMPRESSION_LOGGING_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                body: JSON.stringify(itemsCloned),
            });
        } catch (_) {
            /**
             * these are connectivity errors, nothing to do.
             * Consider a retry perhaps
             */
            return;
        }
    };
}
